<div *ngIf="paginationData$ | async as pgData">
  <nav
    class="tw-isolate tw-inline-flex tw--space-x-px tw-rounded-md tw-shadow-sm"
    aria-label="Pagination"
  >
    <button
      (click)="updatePage(pgData.page - 1)"
      class="tw-relative tw-inline-flex tw-items-center tw-rounded-l-md tw-border tw-border-gray-300 tw-bg-white tw-px-2 tw-py-2 tw-text-sm tw-font-medium hover:tw-bg-gray-50 focus:tw-z-20"
      [ngClass]="{
        'tw-text-gray-500 tw-cursor-pointer': pgData.canDoPrevious,
        'tw-text-gray-300 hover:tw-text-gray-300': !pgData.canDoPrevious
      }"
      [disabled]="!pgData.canDoPrevious"
    >
      <span class="tw-sr-only">Previous</span>
      <!-- Heroicon name: mini/chevron-left -->
      <svg
        class="tw-h-5 tw-w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
    <ng-container>
      <a
        *ngFor="let page of pgData.pageList"
        aria-current="page"
        class="tw-relative tw-inline-flex tw-items-center tw-border tw-px-4 tw-py-2 tw-text-sm tw-font-medium focus:tw-z-20 tw-min-w-[2ch]"
        [ngClass]="{
          'tw-z-10 tw-text-indigo-600 tw-border-indigo-500 tw-bg-indigo-50 tw-cursor-default':
            pgData.page == page,
          'tw-text-gray-500 hover:tw-bg-gray-50 tw-border-gray-300 tw-bg-white tw-cursor-pointer':
            pgData.page != page
        }"
        (click)="updatePage(page)"
      >
        {{ page }}
      </a>
    </ng-container>
    <button
      (click)="updatePage(pgData.page + 1)"
      class="tw-relative tw-inline-flex tw-items-center tw-rounded-r-md tw-border tw-border-gray-300 tw-bg-white tw-px-2 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50 focus:tw-z-20"
      [ngClass]="{
        'tw-text-gray-500 tw-cursor-pointer': pgData.canDoNext,
        'tw-text-gray-300 hover:tw-text-gray-300': !pgData.canDoNext
      }"
      [disabled]="!pgData.canDoNext"
    >
      <span class="tw-sr-only">Next</span>
      <!-- Heroicon name: mini/chevron-right -->
      <svg
        class="tw-h-5 tw-w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </nav>
</div>
