<div class="tw-px-2 tw-mx-auto tw-max-w-7xl sm:tw-px-8 2xl:tw-px-0">
  <ng-container *ngIf="curator$ | async as curator">
    <curator-profile [curator]="curator"></curator-profile>
    <div>
      <div class="tw-flex tw-flex-col tw-gap-4 tw-pb-4">
        <div *ngFor="let stats of statistics$ | async">
          <curator-stat-card [statistics]="stats" [curator]="curator"></curator-stat-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>