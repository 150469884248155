<div class="tw-flex tw-flex-col tw-px-10 tw-py-6">
    <h2 class="tw-text-lg lg:tw-text-xl tw-font-bold tw-font-dm-sans">Edit Profile</h2>
    <form class="tw-flex tw-w-full tw-flex-row tw-flex-wrap" [formGroup]="form" (submit)="submitForm()">
        <div class="tw-w-full tw-py-2 sm:tw-py-4 tw-mr-auto form-control-element">
            <label class="tw-mb-2" for="email">Email Address:</label>
            <input type="email" name="" id="" class="tw-bg-white tw-rounded tw-border-gray-500" formControlName="email">
            <small class="tw-text-sm tw-text-red-500 tw-font-normal tw-py-2"
                *ngIf="email.errors?.['email'] && (email.dirty || email.touched)">Please enter a valid email
                address.</small>
        </div>
        <div class="tw-w-full tw-mt-2 tw-pb-2 sm:tw-pb-4 tw-mr-auto">
            <label for="privacy">Privacy Setting:</label>

            <fieldset class="tw-grid tw-grid-cols-2 tw-gap-3 tw-my-4 tw-w-full">
                <!-- Public Button -->
                <div class="tw-w-full">
                    <label for="public" (click)="selectRadio('public')"
                        class="tw-block tw-cursor-pointer tw-w-full tw-h-full tw-rounded-lg tw-border-2 tw-bg-white tw-p-2 tw-text-sm tw-font-medium tw-shadow-md"
                        [ngClass]="{
                                    'tw-border-2 tw-bg-purple-50 tw-border-apicuron-bright-purple': privacy === 'public',
                                    'tw-border-gray-100': privacy !== 'public',
                                    'tw-opacity-50': privacy !== 'public'
                                }">
                        <div class="tw-flex tw-p-1 tw-gap-2  tw-items-start">
                            <div class="tw-col-span-2">
                                <img class="tw-h-6 tw-max-w-full tw-max-h-full" src="../../assets/public.svg"
                                    alt="Public Icon" />
                            </div>
                            <div class="tw-col-span-10">
                                <div class="tw-flex tw-items-center tw-gap-2 tw-pb-1">
                                    <span class="tw-font-bold">Public</span>
                                    <span *ngIf="privacy === 'public'"
                                        class="tw-text-xs tw-bg-purple-100 tw-text-purple-800 tw-border tw-border-apicuron-bright-purple tw-rounded-full tw-px-2 tw-py-0.5">
                                        Current
                                    </span>
                                </div>
                                <span class="tw-text-sm tw-font-normal tw-text-gray-600">Your profile is visible to
                                    everyone</span>
                            </div>
                        </div>
                        <input type="radio" name="privacy" value="public" id="public" class="peer/public tw-hidden"
                            formControlName="privacy" />
                    </label>
                </div>

                <!-- Private Button -->
                <div class="tw-w-full">
                    <label for="private" (click)="selectRadio('private')"
                        class="tw-block tw-cursor-pointer tw-w-full tw-h-full tw-rounded-lg tw-border-2 tw-bg-white tw-p-2 tw-text-sm tw-font-medium tw-shadow-md"
                        [ngClass]="{
                                    'tw-border-2 tw-bg-purple-50 tw-border-apicuron-bright-purple': privacy === 'private',
                                    'tw-border-gray-100': privacy !== 'private',
                                    'tw-opacity-50': privacy !== 'private'
                                }">
                        <div class="tw-flex tw-p-1 tw-gap-2  tw-items-start">
                            <div class="tw-col-span-2">
                                <img class="tw-h-6 tw-max-w-full tw-max-h-full" src="../../assets/private.svg"
                                    alt="Private Icon" />
                            </div>
                            <div class="tw-col-span-10">
                                <div class="tw-flex tw-items-center tw-gap-2 tw-pb-1">
                                    <span class="tw-font-bold">Private</span>
                                    <span *ngIf="privacy === 'private'"
                                        class="tw-text-xs tw-bg-purple-100 tw-text-purple-800 tw-border tw-border-apicuron-bright-purple tw-rounded-full tw-px-2 tw-py-0.5">
                                        Current
                                    </span>
                                </div>
                                <span class="tw-text-sm tw-font-normal tw-text-gray-600">Your profile is only visible to
                                    you</span>
                            </div>
                        </div>
                        <input type="radio" name="privacy" value="private" id="private" class="peer/private tw-hidden"
                            formControlName="privacy" />
                    </label>
                </div>
            </fieldset>
        </div>

        <div class="tw-flex tw-w-full tw-flex-row tw-justify-center tw-py-2">
            <input type="submit" value="submit"
                class="tw-cursor-pointer tw-flex tw-w-1/2 sm:tw-w-1/5 tw-rounded-md tw-bg-apicuron-bright-purple tw-px-3.5 tw-pt-2.5 tw-pb-3.5 tw-text-center tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm tw-hover:bg-indigo-500 tw-focus-visible:outline tw-focus-visible:outline-2 tw-focus-visible:outline-offset-2 tw-focus-visible:outline-indigo-600 tw-capitalize">
        </div>
    </form>
</div>