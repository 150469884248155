<div
  class="tw-w-full tw-h-full tw-bg-white sm:tw-px-6 sm:tw-py-4 tw-px-1 tw-py-2 tw-flex tw-flex-col tw-overflow-y-auto lg:tw-overflow-y-hidden"
>
  <h2
    class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-capitalize"
    id="slide-over-title"
  >
    {{ curator.displayedName }}'s Earned Badges on
    <span [ngStyle]="{ color: resource.color }">{{
      resource.resource_name
    }}</span>
  </h2>

  <div class="tw-mt-6 tw-relative">
    <div class="tw-flex tw-flex-row tw-flex-wrap">
      <ng-container *ngIf="badges$ | async as badges">
        <div
          class="tw-w-1/2 md:tw-w-1/3 lg:tw-w-1/4 tw-p-2 md:tw-p-1"
          *ngFor="let badge of badges.data"
        >
          <badge-card [badge]="badge" [terms]="terms$ | async"></badge-card>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="tw-mt-auto tw-flex tw-flex-row tw-justify-center"
    *ngIf="badges$ | async as badges"
  >
    <pagination
      [totalItems]="badges.meta.pagination.total"
      [itemsPerPage]="badges.meta.pagination.limit"
      (pageChanged)="update_page($event.page)"
      [maxSize]="5"
    ></pagination>
  </div>
</div>
