<div class="tw-flex tw-h-screen tw-flex-col tw-border-e tw-bg-white tw-w-full">
    <!-- Main Content Area -->
    <div class="tw-flex-1 tw-overflow-y-auto">
        <!-- Container 1: General and Edit Profile -->
        <div class="tw-px-4 tw-py-3 tw-border-b tw-border-gray-200">
            <div class="tw-flex tw-items-center tw-justify-center">
                <a routerLink="/"
                    class="tw-grid tw-h-10 tw-w-32 tw-place-content-center tw-rounded-lg tw-text-xs tw-text-gray-600">
                    <svg-icon src="assets/APICURON.svg" svgClass="tw-w-full"></svg-icon>
                </a>
            </div>

            <ul class="tw-pt-5 tw-mt-5 tw-space-y-1 tw-gap-4 tw-border-t tw-border-gray-200">
                <li>
                    <a routerLink="/dashboard" routerLinkActive="list-item-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="tw-block tw-text-black tw-font-medium tw-rounded-lg tw-px-4 tw-py-1 tw-text-sm list-item list-item-colors">
                        General
                    </a>
                    <a [routerLink]="['/dashboard', 'profile']" routerLinkActive="list-item-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="tw-block tw-text-black tw-font-medium  tw-rounded-lg tw-px-4 tw-py-1 tw-text-sm list-item list-item-colors">
                        Edit Profile
                    </a>
                </li>
            </ul>
        </div>

        <!-- Container 2: Contributed Resources -->
        <div class="tw-px-4 tw-py-2 tw-border-b tw-border-gray-200">
            <ng-container *ngIf="contributedResources$ | async as contributedResources">
                <span class="tw-font-medium tw-text-gray-400 tw-uppercase tw-text-xs tw-mt-5">Contributed
                    Resources</span>

                <ul class="tw-space-y-1 tw-mt-1">
                    <ng-container *ngFor="let resource of contributedResources">
                        <li>
                            <a [routerLink]="['/dashboard', 'user', 'resources', resource.resource_id]"
                                routerLinkActive="list-item-active"
                                class="tw-text-black tw-font-medium  tw-block tw-rounded-lg tw-px-4 tw-py-1 tw-text-sm list-item list-item-colors">
                                {{ resource.resource_name }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>

        <!-- Container 3: Manage Resources -->
        <div class="tw-px-4 tw-py-2 tw-border-b tw-border-gray-200">
            <ng-container *ngIf="links$ | async as resourcesLinks">
                <span class="tw-font-medium tw-text-gray-400 tw-text-xs tw-uppercase">Manage Resources</span>

                <ul class="tw-space-y-1 tw-mt-1">
                    <ng-container *ngFor="let resourceLinks of resourcesLinks">
                        <li>
                            <details class="tw-group summary::-webkit-details-marker]:tw-hidden"
                                [open]="openDetails(resourceLinks)">
                                <summary
                                    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-px-4 tw-py-2 list-item list-item-colors">
                                    <span class="tw-text-sm tw-font-medium ">
                                        <img [src]="resourceLinks.resource.faviconLink()" alt=""
                                            class="tw-inline  tw-w-4 tw-h-4">
                                        {{ resourceLinks.resource.resource_name }}
                                    </span>
                                    <span class="tw-shrink-0 tw-transition tw-duration-300 group-open:tw--rotate-180">
                                        <svg-icon src="assets/svg/chevron_down.svg"
                                            svgClass="tw-h-4 tw-w-4 tw-stroke-2"></svg-icon>
                                    </span>
                                </summary>

                                <ul class="tw-mt-2 tw-space-y-1 tw-px-2">
                                    <li *ngFor="let link of resourceLinks.links">
                                        <a [routerLink]="link.href" [routerLinkActiveOptions]="{ exact: true }"
                                            routerLinkActive="list-item-active"
                                            class="tw-flex tw-w-full tw-flex-row tw-justify-start tw-items-center tw-rounded-lg tw-px-4 tw-py-2 tw-text-sm list-item list-item-colors">
                                            <svg-icon [src]="link.icon" svgClass="tw-w-4 tw-h-4"></svg-icon>
                                            <span class="tw-pl-2">{{ link.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
    </div>

    <!-- Footer (Stays at Bottom) -->
    <div>
        <a href="#"
            class="tw-m-4 tw-rounded-xl tw-bg-gray-100 tw-flex tw-items-center tw-gap-2 tw-p-4 hover:tw-bg-gray-100"
            *ngIf="user$ | async as user">
            <img alt="Man" src="http://localhost:3000/api/public/users/apicuron-test-0e422b2f.svg"
                class="tw-h-10 tw-w-10 tw-rounded-full tw-object-cover">
            <div>
                <p class="tw-text-md">
                    <strong class="tw-block">{{ user.displayedName }}</strong>
                </p>
            </div>
        </a>
    </div>
</div>