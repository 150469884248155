<div class="tw-px-2 tw-mx-auto tw-max-w-7xl sm:tw-px-8 2xl:tw-px-0">
    <div class="tw-py-2 md:tw-pt-4 md:tw-pb-4 tw-flex tw-flex-row tw-flex-wrap tw-justify-between">
        <h2 class="tw-text-3xl tw-font-bold tw-font-dm-sans">Curators</h2>

    </div>
    <curator-search (urlStringEvent)="update_curator_list($event)"></curator-search>
    <ng-container *ngIf="biocurators_list$ | async as biocurators">
        <div *ngIf="biocurators.length > 0"
            class="tw-flex tw-flex-row tw-flex-wrap tw-py-2 lg:tw-py-4 lg:tw-my-6">
            <div *ngFor="let entry of biocurators" class="sm:tw-w-1/3 tw-w-1/2 tw-p-2">
                <curator-card [biocurator]="entry.biocurator" [stats]="entry.stats" [vertical]="false" ></curator-card>
            </div>
        </div>
        <div *ngIf="biocurators.length == 0">

            <svg class="tw-mx-auto tw-w-64 tw-text-apicuron-purple" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <g fill="currentColor">
                    <path
                        d="m134.88 121.52c0 6.6289-5.3711 12-12 12-6.625 0-12-5.3711-12-12 0-6.625 5.375-12 12-12 6.6289 0 12 5.375 12 12" />
                    <path
                        d="m186.88 121.52c0 6.6289-5.3711 12-12 12-6.625 0-12-5.3711-12-12 0-6.625 5.375-12 12-12 6.6289 0 12 5.375 12 12" />
                    <path
                        d="m238.88 121.52c0 6.6289-5.3711 12-12 12-6.625 0-12-5.3711-12-12 0-6.625 5.375-12 12-12 6.6289 0 12 5.375 12 12" />
                    <path
                        d="m256 386c28.141 0 55.125-11.18 75.02-31.078 19.895-19.902 31.066-46.891 31.059-75.027-0.003906-28.141-11.191-55.125-31.098-75.012-19.902-19.891-46.895-31.059-75.035-31.043-28.137 0.015625-55.117 11.207-75.004 31.117-19.883 19.91-31.043 46.902-31.02 75.043 0.0625 28.105 11.262 55.043 31.145 74.91 19.883 19.867 46.828 31.047 74.934 31.09zm0-188.16c21.766 0 42.637 8.6445 58.031 24.031 15.391 15.387 24.043 36.258 24.047 58.023 0.007813 21.762-8.6289 42.641-24.012 58.035-15.383 15.398-36.25 24.055-58.012 24.07-21.766 0.015625-42.645-8.6172-58.047-23.992-15.402-15.379-24.066-36.242-24.086-58.008 0-21.777 8.6445-42.66 24.035-58.066s36.266-24.074 58.043-24.094z" />
                    <path
                        d="m222.8 313.04c2.2383 2.2695 5.293 3.543 8.4805 3.543 3.1836 0 6.2383-1.2734 8.4805-3.543l16-16 16 16c2.2383 2.2695 5.293 3.543 8.4766 3.543 3.1875 0 6.2422-1.2734 8.4805-3.543 4.6367-4.7031 4.6367-12.258 0-16.961l-16-16 16-16c4.6367-4.6992 4.6367-12.254 0-16.957-2.2383-2.2695-5.293-3.543-8.4805-3.543-3.1836 0-6.2383 1.2734-8.4766 3.543l-16 16-16-16c-2.2422-2.2695-5.2969-3.543-8.4805-3.543-3.1875 0-6.2422 1.2734-8.4805 3.543-4.6367 4.7031-4.6367 12.258 0 16.957l16 16-16 16c-4.6367 4.7031-4.6367 12.258 0 16.961z" />
                    <path
                        d="m115.92 448h280c13.789 0.019531 27.023-5.4375 36.793-15.176 9.7656-9.7344 15.266-22.953 15.285-36.746v-280c0.019531-13.789-5.4375-27.023-15.176-36.793-9.7344-9.7656-22.953-15.266-36.746-15.285h-280c-13.789-0.019531-27.023 5.4375-36.793 15.176-9.7656 9.7344-15.266 22.953-15.285 36.746v280c-0.019531 13.789 5.4375 27.023 15.176 36.793 9.7344 9.7656 22.953 15.266 36.746 15.285zm-27.922-332.08c0.019531-7.3984 2.9688-14.488 8.1992-19.723 5.2344-5.2305 12.324-8.1797 19.723-8.1992h280c7.4258-0.019531 14.555 2.9102 19.82 8.1445 5.2656 5.2344 8.2383 12.348 8.2578 19.777v280c0.019531 7.4258-2.9102 14.555-8.1445 19.82-5.2344 5.2656-12.348 8.2383-19.777 8.2578h-280c-7.4258 0.019531-14.555-2.9102-19.82-8.1445-5.2656-5.2344-8.2383-12.348-8.2578-19.777z" />
                </g>
            </svg>
            <span class="tw-block tw-text-center tw-text-2xl tw-capitalize tw-font-bold ">No Curators Match your Search</span>
        </div>
    </ng-container>

    <div class="tw-flex tw-justify-center tw-my-2">

        <pagination [totalItems]="(biocurators_count$ | async)" [itemsPerPage]="limit"
            [(ngModel)]="current_page" (pageChanged)="update_page($event.page)" [maxSize]="5"></pagination>
    </div>
</div>