<div [loadable]="data$">
  <ng-container *ngIf="resource$ | async as resource">
    <div *ngIf="options$ | async as options" class="tw-font-dm-sans">
      <div class="sm:tw-hidden">
        <label for="Tab" class="tw-sr-only">Tab</label>

        <select id="Tab" class="tw-w-full tw-rounded-md tw-border-gray-200 tw-font-dm-sans">
          <option *ngFor="let option of options" (click)="selectLeaderboard(option)" [ngClass]="{
              'tw-bg-apicuron-purple tw-text-white tw-font-bold':
                option.leaderboard === (selectedOption$ | async).leaderboard,
              'tw-border-t tw-border-r tw-border-gray-500':
                option.leaderboard != (selectedOption$ | async).leaderboard
            }">
            {{ option.leaderboard }}
          </option>
        </select>
      </div>

      <div class="tw-hidden sm:tw-block">
        <div class="tw-border-b tw-border-gray-200">
          <nav class="tw--mb-px tw-flex tw-gap-1">
            <button *ngFor="let option of options" (click)="selectLeaderboard(option)" [ngClass]="{
				'tw-bg-apicuron-bright-purple tw-text-white': option.leaderboard === (selectedOption$ | async).leaderboard,
				'tw-bg-white tw-text-gray-700 hover:tw-text-gray-800 tw-shadow-inner': option.leaderboard != (selectedOption$ | async).leaderboard,
				}" class="shrink-0 tw-border tw-border-transparent tw-rounded-t-lg tw-p-1 tw-text-sm tw-font-bold
              tw-transition-all tw-duration-200 tw-ease-in">
              {{ option.label }}
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="selectedOption$ | async as option"
      class="tw-bg-white tw-rounded-b-lg tw-rounded-r-lg tw-shadow-md tw-p-1">
      <ng-container [ngSwitch]="option.leaderboard">
        <ng-container *ngSwitchCase="'total-leaderboard'" class="tw-p-1 xl:tw-p-2">
          <total-leaderboard [resource]="resource" [columns]="option.columns"></total-leaderboard>
        </ng-container>
        <ng-container *ngSwitchCase="'weekly-leaderboard'">
          <weekly-leaderboard [resource]="resource" [columns]="option.columns"></weekly-leaderboard>
        </ng-container>

        <ng-container *ngSwitchCase="'monthly-leaderboard'">
          <monthly-leaderboard [resource]="resource" [columns]="option.columns"></monthly-leaderboard>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>