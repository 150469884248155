<div>
  <div>
    <div class="main-content">
      <div class="">
        <h2 class="tw-text-3xl tw-font-dm-sans tw-font-bold">Partner Resources</h2>
      </div>

    </div>
  </div>




  <div class="main-content">
    <div class="tw-flex tw-flex-row tw-flex-wrap">
      <div class="tw-w-full lg:tw-w-3/4 tw-px-2">
        <div class="lg:tw-mt-3">
          <p class="tw-leading-7 tw-text-base 2wl:tw-text-lg tw-text-justify" style="text-indent:2rem; hyphens: auto;">
            This page lists all <strong>partner resources</strong> registered to APICURON. Member databases
            periodically push curation activities in APICURON.
            By clicking on the "View details" button you can visit resource pages where you can find the
            <strong>leaderboard</strong>, the
            <strong>achievements definition</strong>
            (badges, medals) and the full list of <strong>curation records</strong>.

          </p>
        </div>

        <div
          class="tw-mt-2 lg:tw-mt-4 tw-bg-orange-100 tw-border-orange-500 tw-text-orange-700 tw-border-l-4 tw-p-2 lg:tw-p-4">
          <span class="tw-block tw-font-bold tw-font-dm-sans">Disclaimer</span>
          <p class=" tw-leading-7">
            <strong>A comparison across databases is meaningless </strong>.
            Different curation databases evaluate and weight curation activities differently.
            Each database defines <strong>curation activities</strong> at <strong>different granularity</strong>.
            The <strong>scores</strong> assigned to curation activities may have <strong>different meanings</strong> in
            different databases.
            For some databases scores are proportional to the curation effort, for other databases scores
            represent the importance of an activity.

          </p>
        </div>
      </div>
      <div class="tw-w-full lg:tw-w-1/4 tw-py-4">
        <div class="tw-bg-gray-200 tw-border-2 tw-border-gray-400 tw-rounded tw-px-2 md:tw-px-4 tw-pb-2 md:tw-pb-4">
          <span class="tw-block tw-uppercase tw-text-xs tw-text-gray-400 tw-font-bold tw-pt-1">Resources</span>
          <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-1" *ngIf="resources$ | async as resources">
            <ng-container *ngFor="let currResource of resources">
              <a class="database-pill" routerLink="." [fragment]="'resource_' + currResource.resource_id">
                {{ currResource.resource_name }}
              </a>

            </ng-container>

          </div>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-row tw-flex-wrap tw-pt-2 tw-justify-center md:tw-pt-4"
      *ngIf="resources$ | async as resources">
      <ng-container *ngFor="let resource of resources">
        <div class="tw-w-full lg:tw-w-1/2 tw-p-2" [id]="'resource_' + resource.resource_id">
          <partner-resource [resource]="resource">
          </partner-resource>
        </div>
      </ng-container>

    </div>
  </div>