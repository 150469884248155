<div
  class="tw-bg-white tw-rounded-md tw-shadow-md tw-px-2 tw-py-2 tw-h-full tw-flex tw-flex-col tw-overflow-hidden tw-shadow-slate-400
  tw-transition-all tw-ease-linear tw-duration-200
  hover:tw-shadow-slate-600 hover:tw-scale-[1.02]"
>
  <span
    class="tw-text-gray-800 tw-font-dm-sans tw-text-lg tw-font-semibold tw-mb-0"
    [title]="term.activity_term"
  >
    {{ term.name }}
  </span>
  <p class="tw-text-gray-500 tw-font-medium tw-mb-4 tw-text-sm tw-text-justify tw-line-clamp-2" [title]="term.description">
    {{ term.description }}
  </p>
  <div
    class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-auto"
  >
    <div class="tw-flex tw-flex-row tw-items-center">
      <div
        [title]="'score value for ' + term.name"
        
        class="tw-rounded-full tw-h-7 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-mr-1
        tw-text-slate-800 tw-bg-gray-300"
      > 
      <!-- [ngClass]="scoreColor()" -->
        <span class="tw-font-bold tw-text-sm tw-cursor-default ">{{
          term.score
        }}</span>
      </div>
    </div>
    <div>
      <span
        class="tw-text-sm tw-font-bold tw-rounded-full tw-px-2 tw-py-1"
        [ngClass]="categoryColor()"
        >{{ term.category | titlecase }}</span
      >
    </div>
  </div>
</div>
