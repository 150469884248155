<article
  class="tw-flex tw-items-center tw-gap-4 tw-rounded tw-border tw-border-gray-300 tw-bg-white tw-p-2 sm:tw-p-4 2xl:tw-p-6 ">
  <span class="tw-rounded-full tw-bg-purple-100 tw-p-1 md:tw-p-2 tw-text-apicuron-purple">
    <svg-icon [src]="iconSrc" svgClass="tw-w-10 tw-h-10 tw-fill-apicuron-purple"></svg-icon>
  </span>

  <div>
    <p class="tw-text-xl tw-font-medium tw-text-gray-900">{{ value }}</p>

    <p class="tw-py-1 tw-text-sm tw-font-bold tw-text-gray-500">{{ label }}</p>
  </div>
</article>