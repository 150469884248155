<div class="tw-w-full tw-relative">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-h-full tw-cursor-pointer"
        (click)="dropdownOpen= !dropdownOpen">
        <span class="tw-text-gray-800 tw-py-2 tw-px-1 tw-block ">{{ hintText }}</span>
        <button class="tw-w-4 tw-h-4">
            <svg-icon src="assets/svg/chevron_down.svg" svgClass="tw-w-4 tw-h-4 tw-transform tw-transition-transform tw-duration-500 tw-ease-in-out"
                [svgStyle]="{ transform: dropdownOpen ? 'rotate3d(1,0,0,180deg)' : 'rotate3d(1,0,0,0deg)'}"></svg-icon>
        </button>
    </div>
    <div class="tw-absolute tw-bg-white tw-w-full tw-rounded-md tw-border tw-border-gray-400 tw-py-1 tw-z-50"
        [ngClass]="{'tw-hidden': !dropdownOpen}" (mouseleave)="dropdownOpen = false">
        <div class="tw-flex tw-flex-col">
            <div *ngFor="let item of completeSet" (click)="selectItem(item)"
                [ngClass]="{'tw-bg-apicuron-bright-purple tw-text-gray-100 ': itemIsSelected(item), 'tw-text-gray-700 tw-bg-white hover:tw-bg-apicuron-bright-purple hover:tw-text-gray-100': !itemIsSelected(item) }"
                class="tw-flex tw-flex-row tw-justify-between tw-p-1 tw-border-0 tw-cursor-pointer">
                <span class="tw-text-sm">{{ item.text }}</span>
                <div [ngClass]="{ 'tw-block': itemIsSelected(item), 'tw-hidden': !itemIsSelected(item)}">
                    <svg-icon src="assets/svg/check.svg" svgClass="tw-w-4 tw-h-4 tw-text-white"></svg-icon>
                </div>
            </div>

        </div>
    </div>
</div>