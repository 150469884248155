<div>
  <!-- <app-navbar></app-navbar> -->

  <!-- <navbar></navbar> -->
  <div class="tw-min-h-screen tw-bg-gray-100">
    <!-- <div class="tw-flex tw-justify-center tw-p-4">
      <app-medal-generator></app-medal-generator>
    </div> -->
    <router-outlet></router-outlet>
  </div>
  <!-- <app-foot></app-foot> -->
</div>