<div class="tw-rounded-md tw-font-dm-sans tw-p-1 tw-h-full"
    [ngClass]="{'tw-shadow-md tw-shadow-gray-400': enableShadow }">

    <div class="tw-flex tw-flex-row">
        <div class="tw-shrink-0">
            <img src="assets/svg/gold_medal.svg" class="tw-w-16 sm:tw-w-20 lg:tw-w-24" alt="">
        </div>
        <div class=" tw-pl-1">
            <span class="tw-text-sm sm:tw-text-base tw-text-gray-700 tw-font-bold tw-block tw-text-left">{{ medal.name
                }}</span>
            <span class="tw-font-bold tw-block tw-text-gray-500 tw-text-sm tw-leading-relaxed">Ranked <span
                    class="tw-text-gray-700">{{ medal.getRanking() }}</span></span>
            <span *ngIf="medal.score_relative > 1"
                class="tw-font-bold tw-block tw-text-gray-500 tw-text-sm tw-leading-relaxed">Among {{
                medal.score_relative }} biocurators</span>
        </div>
    </div>
</div>