<div
  class="tw-w-full tw-h-full tw-bg-white sm:tw-px-6 sm:tw-py-4 tw-px-1 tw-py-2 tw-flex tw-flex-col"
>
  <h2
    class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900 tw-capitalize"
    id="slide-over-title"
  >
    {{ curator.displayedName }}'s Earned Medals on
    <span [ngStyle]="{ color: resource.color }">{{
      resource.resource_name
    }}</span>
  </h2>

  <div class="tw-mt-6 tw-relative">
    <div class="tw-flex tw-flex-row tw-flex-wrap">
      <ng-container *ngIf="medals$ | async as medals">
        <div class="tw-w-1/4 tw-p-1" *ngFor="let medal of medals.data">
          <medal-card [medal]="medal"></medal-card>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="tw-mt-auto tw-flex tw-flex-row tw-justify-center"
    *ngIf="medals$ | async as medals"
  >
    <pagination
      [totalItems]="medals.meta.pagination.total"
      [itemsPerPage]="medals.meta.pagination.limit"
      (pageChanged)="update_page($event.page)"
      [maxSize]="5"
    ></pagination>
    <!-- <pagination></pagination> -->
  </div>
</div>
